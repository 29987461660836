import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2022/medical-outreach/1.jpg';
import blogd2 from '../../assets/images/2022/medical-outreach/2.jpg';
import blogd3 from '../../assets/images/2022/medical-outreach/3.jpg';
import blogd4 from '../../assets/images/2022/medical-outreach/4.jpg';
import blogd5 from '../../assets/images/2022/medical-outreach/5.jpg';
import blogd6 from '../../assets/images/2022/medical-outreach/6.jpg';
import blogd7 from '../../assets/images/2022/medical-outreach/7.jpg';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => { 
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  Medical Outreach to Mashewele Community Idi- Oro Mushin Lagos
                  by Community Health Department LUTH
                </h2>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  As part of the strategies to bridge the gap in ensuring access
                  to quality healthcare services by the surrounding communities,
                  the department of community health LUTH organized an outreach
                  to Mashewele Community Mushin Lagos. The community is one of
                  the underserved communities in Mushin LGA without a primary
                  health care centre within the ward. The aim of the medical
                  outreach was to provide high quality healthcare services to
                  members of this community thereby improving their health and
                  well-being. There were also referrals for those that require
                  additional care and services to either the Community Health
                  Out Patient (CHOP) clinic or other departments in LUTH as
                  required. The outreach took place on Friday the 17th of June
                  2022 from 9am to about 12 noon. It was a joint effort as it
                  involved health care workers from the department, final year
                  students from the school of nursing and health care workers
                  from Mushin local government with community participation. The
                  department also worked in collaboration with the Lagos State
                  Ministry of Health who provided us with screening services for
                  Tuberculosis.
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Consultative Services Ongoing at the Outreach
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Services rendered to this community included basic
                  anthropometric measurements, measurement of BMI and blood
                  pressure, consultation services, TB Screening, HIV screening,
                  RDT for Malaria, Random Blood Sugar, COVID 19 immunization,
                  routine immunization for U5 children and medications at
                  minimal cost to the community. Community entry and all other
                  necessary events had been done beforehand and the program was
                  a resounding success. We were able to reach about 150 persons
                  in that community with free consultative services, ran TB
                  screening for persons, HIV screening for over 20 persons.
                  About 40 persons were able to take COVID vaccinations and all
                  people over 50 had a random blood sugar test carried out. Free
                  Long-lasting insecticide treated nets were also distributed at
                  the outreach
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Baale of Mashewele community, community elder, HOD of
                  Community Health Dept Prof Ogunnowo, Dr Roberts ,Mushin LGA
                  Health Educator Mrs Akinterinwa and Residents in Community
                  Health.
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Other pictures as found below:
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Fig 1: Residents at the department before the program
                  <br />
                  Fig 2: Dr Roberts and Dr Balogun Consultants at Dept of Comm
                  Health at the Outreach.
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Residents dispensing medications in advance for the outreach
                </span>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Community Health Department</a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Medical Outreach to Mashewele Community  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
